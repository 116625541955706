import { ApolloError } from '@apollo/client'

import { FacilityQueryResult } from '@/graphql/generated/hooks'
import { FacilityType } from '@/graphql/generated/schemas'
import { OptionI } from '@/utils/forms/selectOptions'

import { LabelValueIProps } from '../types/types'

export type Facility = FacilityQueryResult['data']['facility']

export const latRegex = /^\(?[+-]?(90(\.0+)?|[1-8]?\d(\.\d+)?)$/
export const lonRegex =
  /^\s?[+-]?(180(\.0+)?|1[0-7]\d(\.\d+)?|\d{1,2}(\.\d+)?)\)?$/

export const facilityTypeOptions: LabelValueIProps[] = [
  { label: 'Physical', value: FacilityType.Normal },
  { label: 'Market', value: FacilityType.Market },
]

export const validateLongitude = (value: string, type: FacilityType) => {
  if (type === FacilityType.Market) {
    return true
  }
  if (!value) {
    return 'Facility longitude is required'
  } else if (!lonRegex.test(value)) {
    return 'Enter a valid longitude'
  } else return true
}

export const validateLatitude = (value: string, type: FacilityType) => {
  if (type === FacilityType.Market) {
    return true
  }
  if (!value) {
    return 'Facility latitude is required'
  } else if (!latRegex.test(value)) {
    return 'Enter a valid latitude'
  } else return true
}

export const getFacilityTypeOption = (facility: Facility) => {
  return facilityTypeOptions.find((t) => t.value === facility?.type)
}

export const getFloorOptions = (facility: Facility): OptionI[] =>
  facility?.floors?.edges.map((f) => {
    return { label: f.node.name, value: f.node?.id }
  }) || []

export const getDefaultFloorOption = (facility: Facility): OptionI => {
  const defaultFloorId = facility?.defaultFloorId
  const floorOptions = getFloorOptions(facility)

  if (floorOptions.length === 0) {
    return null
  }
  return (
    floorOptions.find(({ value }) => value === defaultFloorId) ||
    floorOptions[0]
  )
}

export const getErrorMessage = (
  e: ApolloError,
  name: string,
  crudType: string
) => {
  if (e?.graphQLErrors?.[0]?.extensions?.code === 'facility.name.not.unique') {
    return `Error: ${e.message}`
  } else {
    return `Error ${crudType} ${name} Facility`
  }
}
