import { CgUser } from 'react-icons/cg'
import { HiCheck } from 'react-icons/hi'

import { Box, Icon, Spacer } from '@chakra-ui/react'

import {
  IncidentNote,
  OrderRepair,
  Share,
  SystemAction,
} from '@/components/icons'
import { CallEnd, CallStart, VoiceMessage } from '@/components/icons'
import { IncidentActionType, IncidentStatus } from '@/graphql/generated/schemas'

import { NonActionItem } from '../types/types'
import { IncidentStatusTag } from './IncidentStatus'

export function creatorRoleName(roleName: string) {
  switch (roleName) {
    case 'GUARD':
      return 'Guard'
    case 'PROGRAM_MANAGER':
      return 'PM'
    case 'IMPLEMENTER':
      return 'Implementer'
    default:
      return 'Operator'
  }
}

export const IncidentHeader = (incidentName: string) => (
  <Box direction='row' display='flex'>
    <Box as='span' fontWeight='semibold' mr='15px'>
      {incidentName}
    </Box>
    <Spacer />
    <Box ml='5px'>
      <IncidentStatusTag status={IncidentStatus.Active} />
    </Box>
  </Box>
)

interface ActionHeaderIProps {
  actionType: IncidentActionType | NonActionItem
  username?: string
  roleName?: string
  systemMessage?: string
  technicianName?: string
}

export const ActionHeader = ({
  actionType,
  username,
  roleName,
  systemMessage,
  technicianName,
}: ActionHeaderIProps) => {
  switch (actionType) {
    case IncidentActionType.AcknowledgementByGuard:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username} `}
          </Box>
          <Box as='span' color='#2E6CDB' fontWeight='bold'>
            Acknowledged{' '}
          </Box>
          the Incident.
        </Box>
      )
    case IncidentActionType.TakeoverByGuard:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username} `}
          </Box>
          <Box as='span' color='#2E6CDB' fontWeight='bold'>
            Took Over{' '}
          </Box>
          the Incident.
        </Box>
      )
    case IncidentActionType.AcknowledgementByOperator:
      return (
        <Box direction='row' display='flex'>
          <Box data-testid='ActionHeader:header'>
            <Box as='span' fontWeight='bold'>
              {`${creatorRoleName(roleName)} ${username} `}
            </Box>
            <Box as='span' color='#2E6CDB' fontWeight='bold'>
              Acknowledged{' '}
            </Box>
            the Incident.
          </Box>
          <Spacer />
          <Box ml='5px'>
            <IncidentStatusTag status={IncidentStatus.InProgress} />
          </Box>
        </Box>
      )
    case IncidentActionType.OnScene:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username} `}
          </Box>
          is{' '}
          <Box as='span' color='#2E6CDB' fontWeight='bold'>
            On Scene.
          </Box>
        </Box>
      )
    case IncidentActionType.ReleaseByGuard:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username} `}
          </Box>
          <Box as='span' color='#2E6CDB' fontWeight='bold'>
            Released{' '}
          </Box>
          the Incident.
        </Box>
      )
    case IncidentActionType.ReleaseByOperator:
      return (
        <Box direction='row' display='flex'>
          <Box data-testid='ActionHeader:header'>
            <Box as='span' fontWeight='bold'>
              {`${creatorRoleName(roleName)} ${username} `}
            </Box>
            <Box as='span' color='#2E6CDB' fontWeight='bold'>
              Released{' '}
            </Box>
            the Incident.
          </Box>
          <Spacer />
          <Box ml='5px'>
            <IncidentStatusTag status={IncidentStatus.Active} />
          </Box>
        </Box>
      )
    case IncidentActionType.Cleared:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username} `}
          </Box>
          <Box as='span' color='#13B0B0' fontWeight='bold'>
            Cleared{' '}
          </Box>
          the Incident.
        </Box>
      )
    case IncidentActionType.Resolved:
      return (
        <Box direction='row' display='flex'>
          <Box data-testid='ActionHeader:header'>
            <Box as='span' fontWeight='bold'>
              {`${creatorRoleName(roleName)} ${username} `}
            </Box>
            <Box as='span' color='#13B0B0' fontWeight='bold'>
              Resolved{' '}
            </Box>
            the Incident.
          </Box>
          <Spacer />
          <Box ml='5px'>
            <IncidentStatusTag status={IncidentStatus.Resolved} />
          </Box>
        </Box>
      )
    case IncidentActionType.OrderDeviceRepair:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username} `}
          </Box>
          <Box as='span' color='#13B0B0' fontWeight='bold'>
            contacted Technician{' '}
          </Box>
          {technicianName}.
        </Box>
      )
    case IncidentActionType.SetRealToFalse:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username} `}
          </Box>
          has marked the Incident as{' '}
          <Box as='span' color='#2E6CDB' fontWeight='bold'>
            False.
          </Box>
        </Box>
      )
    case IncidentActionType.SetRealToTrue:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username} `}
          </Box>
          has marked the Incident as{' '}
          <Box as='span' color='#2E6CDB' fontWeight='bold'>
            Real.
          </Box>
        </Box>
      )
    case IncidentActionType.StartEmergencyCall:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username} `}
          </Box>
          called 911
        </Box>
      )
    case IncidentActionType.EndEmergencyCall:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username}`}
          </Box>
          's 911 call ended
        </Box>
      )
    case NonActionItem.IncidentNote:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username} `}
          </Box>
          added a note.
        </Box>
      )
    case NonActionItem.SystemAction:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {systemMessage}
          </Box>
        </Box>
      )
    case NonActionItem.AudioMessage:
      return (
        <Box data-testid='ActionHeader:header'>
          <Box as='span' fontWeight='bold'>
            {`${creatorRoleName(roleName)} ${username} `}
          </Box>
          sent a voice message.
        </Box>
      )
  }
}

export const ActionIcon = (type: string) => {
  switch (type) {
    case IncidentActionType.AcknowledgementByGuard:
    case IncidentActionType.AcknowledgementByOperator:
    case IncidentActionType.TakeoverByGuard:
    case IncidentActionType.Cleared:
    case IncidentActionType.Resolved:
    case IncidentActionType.SetRealToTrue:
    case IncidentActionType.SetRealToFalse:
      return <HiCheck color='#fff' size='22' />
    case IncidentActionType.OnScene:
      return <CgUser color='#fff' size='22' />
    case IncidentActionType.OrderDeviceRepair:
      return <Icon as={OrderRepair} boxSize='18px' />
    case IncidentActionType.ReleaseByOperator:
      return <Icon as={Share} boxSize='18px' />
    case IncidentActionType.StartEmergencyCall:
      return <Icon as={CallStart} boxSize='18px' />
    case IncidentActionType.EndEmergencyCall:
      return <Icon as={CallEnd} boxSize='18px' />
    case NonActionItem.IncidentNote:
      return <Icon as={IncidentNote} boxSize='18px' />
    case NonActionItem.SystemAction:
      return <Icon as={SystemAction} boxSize='26px' />
    case NonActionItem.AudioMessage:
      return <Icon as={VoiceMessage} boxSize='18px' />
  }
}
