import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'
import { usePermissions } from '@/hooks/usePermissions'
import { useSelector } from 'react-redux'

import {
  IncidentStatus,
  ManualIncidentCategory,
} from '@/graphql/generated/schemas'
import { selectMe } from '@/redux/me/meSlice'

interface UseAvailableIncidentActionsIProps {
  incidentStatus?: IncidentStatus
  incidentCategory: ManualIncidentCategory
  ownerId?: string
  outboundPhoneNumber?: string
}

type UseAvailableIncidentActions = ({
  incidentStatus,
  incidentCategory,
  ownerId,
  outboundPhoneNumber,
}: UseAvailableIncidentActionsIProps) => {
  isEmergencyCallEnabled: boolean
  isAddNoteEnabled: boolean
  isDispatchEnabled: boolean
  isRadioGuardsEnabled: boolean
  isOrderRepairEnabled: boolean
  isResolveEnabled: boolean
  isCanReleaseEnabled: boolean
  isTakeOverEnabled: boolean
  isAcknowledgeEnabled: boolean
  isDownloadReportEnabled: boolean
}

export const useAvailableIncidentActions: UseAvailableIncidentActions = ({
  incidentStatus,
  incidentCategory,
  ownerId,
  outboundPhoneNumber,
}) => {
  const isEmergencyCallEnabled = useFeatureFlag(FeatureFlag.emergencyCall)
  const isEmergencyCallConfigured = Boolean(outboundPhoneNumber)
  const me = useSelector(selectMe)

  const { shouldShowDownloadReportButton } = usePermissions()

  // ownership
  const isOwner = ownerId === me?.id
  const hasOwner = Boolean(ownerId)

  // incident status & type
  const isActive = incidentStatus === IncidentStatus.Active
  const isInProgress = incidentStatus === IncidentStatus.InProgress
  const isResolved = incidentStatus === IncidentStatus.Resolved
  const isDeviceHealthIncident = incidentCategory?.name === 'Device Health'

  return {
    isEmergencyCallEnabled:
      isInProgress && isEmergencyCallEnabled && isEmergencyCallConfigured,
    isAddNoteEnabled: isActive || isInProgress || isResolved,
    isDispatchEnabled: isInProgress && isOwner,
    isRadioGuardsEnabled: isInProgress,
    isOrderRepairEnabled: isInProgress && isDeviceHealthIncident && isOwner,
    isResolveEnabled: isInProgress,
    isCanReleaseEnabled: isInProgress && isOwner,
    isTakeOverEnabled: isInProgress && !isOwner,
    isAcknowledgeEnabled: isActive && !hasOwner,
    isDownloadReportEnabled: isResolved && shouldShowDownloadReportButton,
  }
}
