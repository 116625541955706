import { useForm } from 'react-hook-form'
import { HiArrowLeft } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
} from '@chakra-ui/react'

import { Button, FormInputSelectControl } from '@/components/ui'
import { useStandardOperatingProceduresFilterDataQuery } from '@/graphql/generated/hooks'
import {
  selectSOPCreatedAtFilter,
  selectSOPCreatorIdFilter,
  selectSOPFacilityIdFilter,
  setSOPCreatedAtFilter,
  setSOPCreatorIdFilter,
  setSOPFacilityIdFilter,
} from '@/redux/ui/uiSlice'
import { createdAtOptions } from '@/utils/filterDrawers'
import {
  getFacilityOptions,
  getSOPCreatorOptions,
} from '@/utils/forms/selectOptions'

import { getUniqueValues } from '../../utils/helpers'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

export const SOPsFilterDrawer = ({ isOpen, onClose }: IProps) => {
  const dispatch = useDispatch()
  const { data } = useStandardOperatingProceduresFilterDataQuery({
    fetchPolicy: 'network-only',
  })
  const { control, handleSubmit } = useForm({
    reValidateMode: 'onChange',
  })
  const idsFilterValue = useSelector(selectSOPFacilityIdFilter)
  const createdAtFilterValue = useSelector(selectSOPCreatedAtFilter)
  const creatorFilterValue = useSelector(selectSOPCreatorIdFilter)
  const facilityOptions = getFacilityOptions(data)
  let creatorOptions = getSOPCreatorOptions(data)
  creatorOptions = getUniqueValues(creatorOptions)

  const onSubmit = async (values) => {
    const selectedFacilityId = values?.name
    const selectedCreatedAtRange = values?.createdAt
    const selectedCreator = values?.creator
    dispatch(setSOPFacilityIdFilter(selectedFacilityId || null))
    dispatch(setSOPCreatedAtFilter(selectedCreatedAtRange || null))
    dispatch(setSOPCreatorIdFilter(selectedCreator || null))
    onClose()
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='right' size='sm'>
      <DrawerOverlay data-testid='SOPsFilterDrawer:overlay' />
      <DrawerContent>
        <DrawerHeader
          alignItems='center'
          borderBottomWidth='1px'
          borderColor='#D5DCE4'
          d='flex'
          minH='65px'
          px='5'
          py='0'
        >
          <HStack spacing='3'>
            <IconButton
              aria-label='Back button'
              borderRadius='4px'
              data-testid='sopsPage_filterDrawer_backButton'
              h='30px'
              icon={<HiArrowLeft size={18} />}
              minWidth='30px'
              onClick={onClose}
              variant='ghost'
              w='30px'
            />
            <Box fontSize='20px' fontWeight='bold' letterSpacing='-0.53px'>
              Filter SOPs
            </Box>
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          <Box mb='3' mt='6'>
            <FormInputSelectControl
              closeMenuOnSelect={false}
              control={control}
              dataTestId='sopsPage_filterDrawer_facilityName'
              defaultValue={idsFilterValue || null}
              id='name'
              isClearable
              isSearchable
              label='Facility Name'
              options={facilityOptions}
              placeholder='Select Facility'
            />
          </Box>
          <Box mb='3'>
            <FormInputSelectControl
              closeMenuOnSelect={false}
              control={control}
              dataTestId='sopsPage_filterDrawer_createdAt'
              defaultValue={createdAtFilterValue || null}
              id='createdAt'
              isClearable
              isSearchable={false}
              label='Created At'
              options={createdAtOptions}
              placeholder='Select Time Range'
            />
          </Box>
          <Box mb='3'>
            <FormInputSelectControl
              closeMenuOnSelect={false}
              control={control}
              dataTestId='sopsPage_filterDrawer_creator'
              defaultValue={creatorFilterValue || null}
              id='creator'
              isClearable
              isSearchable={false}
              label='Creator'
              options={creatorOptions}
              placeholder='Select Creator'
            />
          </Box>
        </DrawerBody>
        <DrawerFooter borderColor='#D5DCE4' borderTopWidth='1px'>
          <Button
            data-testid='sopsPage_filterDrawer_applyButton'
            isFullWidth
            onClick={handleSubmit(onSubmit)}
          >
            Apply Filters
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
