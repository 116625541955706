import { useDispatch } from 'react-redux'

import {
  Notification,
  NotificationType,
} from '@/graphql/appsync-notifications/types'
import { openIncidentDrawer, setIncident } from '@/redux/ui/uiSlice'

import { AudioNotification } from './AudioNotification'
import { ClearNotification } from './ClearNotification'

interface InboxNotificationIProps {
  notification: Notification
  onMarkAsRead: () => void
}

export const InboxNotification = ({
  notification,
  onMarkAsRead,
}: InboxNotificationIProps) => {
  const dispatch = useDispatch()

  const onNotificationClick = () => {
    dispatch(setIncident({ id: notification?.incidentId }))
    dispatch(openIncidentDrawer())
    onMarkAsRead()
  }

  switch (notification?.notificationType) {
    case NotificationType.AudiomessageNew:
      return (
        <AudioNotification
          notification={notification}
          onClick={onNotificationClick}
        />
      )
    case NotificationType.IncidentClear:
      return (
        <ClearNotification
          notification={notification}
          onClick={onNotificationClick}
        />
      )
    default:
      return null
  }
}
