/* eslint-disable no-console */
import { useGenerateFileUploadUrlMutation } from '@/graphql/generated/hooks'
import { UploadedFileType } from '@/graphql/generated/schemas'

import { UPLOAD_FAILED, UploadError } from './error'
import { ValidationOptionsI, validateFile } from './validation'

interface UploadIProps {
  file: File
  fileType: UploadedFileType
  validationOptions?: ValidationOptionsI
}

export const useUpload = () => {
  const [generateFileUploadUrl] = useGenerateFileUploadUrlMutation()

  const upload = async ({
    file,
    fileType,
    validationOptions,
  }: UploadIProps): Promise<string> => {
    // Validate filename length, file size, extension and MIME type
    await validateFile(file, fileType, validationOptions)

    // Generate upload URL
    const { data: fileUploadUrlData, errors } = await generateFileUploadUrl({
      variables: {
        input: {
          fileType: fileType,
          mimeType: file.type,
          size: file.size,
        },
      },
    })
    if (errors) {
      throw new UploadError(UPLOAD_FAILED)
    }

    // Upload file
    const { fileId, url } = fileUploadUrlData?.generateFileUploadUrl
    const headers = {
      'Content-Type': file.type,
    }

    console.log(fileType)

    if (fileType == UploadedFileType.StandardOperatingProcedure) {
      headers['x-amz-tagging'] = 'scan_result=UNSCANNED'
    }

    const fetchResponse = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: headers,
    })

    if (!fetchResponse.ok) {
      throw new UploadError(UPLOAD_FAILED)
    }

    return fileId
  }

  return [upload]
}
