import { useState } from 'react'

import { useUpload } from '../../../hooks/useUpload/useUpload'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { MAX_FILENAME_LENGTH, MAX_PDF_SIZE } from '@/hooks/useUpload'
import { SubmitHandler, useForm } from 'react-hook-form'
import { BiListUl } from 'react-icons/bi'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import { FormFileUploadControl } from '@/components/ui'
import { useFacilityNamesQuery } from '@/graphql/generated/hooks'
import { useCreateStandardOperatingProcedureMutation } from '@/graphql/generated/hooks'
import { UploadedFileType } from '@/graphql/generated/schemas'
import { getFacilityOptions } from '@/utils/forms/selectOptions'

interface CreateSOPModalIProps {
  isOpen: boolean
  onClose: () => void
}

interface FormInputsIProps {
  title: string
  facilities?: { label: string; value: string }[]
  file: FileList
}

const PDF_MIME_TYPE = 'application/pdf'

export const CreateSOPModal = ({ isOpen, onClose }: CreateSOPModalIProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { shouldEnableCreateSOP } = usePermissions()
  const { showSuccess, showError } = useToasts()

  const [upload] = useUpload()
  const [createStandardOperatingProcedure] =
    useCreateStandardOperatingProcedureMutation()
  const { data: facilitiesData } = useFacilityNamesQuery({
    fetchPolicy: 'network-only',
  })

  const { handleSubmit, register, control, errors } = useForm<FormInputsIProps>(
    {
      reValidateMode: 'onChange',
    }
  )

  const facilityOptions = getFacilityOptions(facilitiesData)

  const onSubmit: SubmitHandler<FormInputsIProps> = async (values) => {
    setIsLoading(true)

    const file = values.file.item(0)
    const sopTitle = values.title
    const facilityIds = values.facilities?.map((f) => f.value) || []

    try {
      const fileId = await upload({
        file,
        fileType: UploadedFileType.StandardOperatingProcedure,
        validationOptions: {
          maxFileSizeInBytes: MAX_PDF_SIZE,
          maxFileNameLength: MAX_FILENAME_LENGTH,
        },
      })

      try {
        const { data } = await createStandardOperatingProcedure({
          variables: {
            input: {
              name: sopTitle,
              externalId: fileId,
              facilityIds: facilityIds,
            },
          },
          refetchQueries: ['StandardOperatingProcedures'],
        })
        const sopReturnedTitle =
          data?.createStandardOperatingProcedure?.standardOperatingProcedure
            ?.name
        showSuccess(`Successfully added ${sopReturnedTitle}`)
        setIsLoading(false)
        onClose()
      } catch (error) {
        // Error creating SOP mutation
        showError(error)
        setIsLoading(false)
      }
    } catch (error) {
      showError(error.message)
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box align-items='center' display='flex' m='20px auto'>
            <Box display='grid' placeItems='center'>
              <BiListUl size='32px' />
            </Box>
            <Box
              color='#353849'
              fontSize='26px'
              fontWeight='extrabold'
              letterSpacing='-0.4px'
              ml={2}
            >
              Add SOP
            </Box>
            <Spacer />
            <ModalCloseButton color='#000' opacity={0.4} position='initial' />
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          <Box px={6} py={2}>
            <Box mb={3}>
              <FormInputControl
                errorMessage={errors.title && errors.title.message}
                id='title'
                inputRef={register({
                  required: 'SOP title is required',
                })}
                isDisabled={isLoading}
                isInvalid={!!errors.title}
                label='SOP Title'
                placeholder='Enter a title for this SOP'
              />
            </Box>
            <Box mb={3}>
              <FormInputSelectControl
                closeMenuOnSelect={false}
                control={control}
                data-testid='CreateSOPModal:facilities'
                defaultValue={null}
                id='facilities'
                isClearable
                isDisabled={isLoading}
                isInvalid={!!errors.facilities}
                isMulti
                isSearchable
                label='Facilities'
                options={facilityOptions}
                placeholder='Add facilities where this SOP applies'
              />
            </Box>
            <Box mb={3}>
              <FormFileUploadControl
                accept='.pdf'
                errorMessage={errors.file && errors.file.message}
                id='file'
                inputRef={register({
                  required: 'SOP PDF is required',
                  validate: (fl: FileList) =>
                    fl?.[0]?.type === PDF_MIME_TYPE ||
                    'File should be in .pdf format',
                })}
                isDisabled={isLoading}
                isInvalid={!!errors.file}
                label='SOP PDF'
                placeholder='Attach PDF'
              />
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter pb={6} py={4}>
          <Button mr={3} onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            isDisabled={!shouldEnableCreateSOP}
            isLoading={isLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Uploading SOP'
            onClick={handleSubmit(onSubmit)}
          >
            Upload SOP
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
