import { Box, CloseButton, Flex } from '@chakra-ui/react'

import { Notification } from '@/graphql/appsync-notifications/types'

import { ClearNotificationTitle } from './ClearNotificationTitle'
import { NotificationIcon } from './NotificationIcon'
import { NotificationSubtitle } from './NotificationSubtitle'

export const ClearNotification = ({
  notification,
  onCancel,
}: {
  notification: Notification
  onCancel?: (incidentTimeStamp: string) => void
}) => {
  return (
    <Flex
      alignItems='center'
      bg='white'
      borderRadius='10px'
      boxShadow='0px 2px 5px 6px rgba(0, 0, 0, 0.2)'
      flexDirection='row'
      h='110px'
      overflow='hidden'
      pe='10px'
    >
      <Flex
        bgColor='#16B3B0'
        boxShadow='0 2px 7px 0 rgba(22,179,176,0.5)'
        h='full'
        transition='ease-in-out'
        w='10px'
      />
      <Box h='full' p='10px' position='relative' w='full'>
        <NotificationIcon
          boxShadow='rgba(22,179,176,0.5)'
          color='#16B3B0'
          size='20px'
        />

        <Box ml='30px' position='relative'>
          <ClearNotificationTitle notification={notification} />
          <NotificationSubtitle
            facilityShortName={notification?.facilityShortName}
            sourceName={notification?.incidentSourceName}
            timestamp={notification?.notificationTimestamp}
          />
        </Box>
      </Box>
      <CloseButton
        color='#D01030'
        onClick={(e) => {
          e.stopPropagation()
          onCancel(notification?.notificationTimestamp)
        }}
        opacity={0.8}
      />
    </Flex>
  )
}
