import { useEffect, useState } from 'react'

import { useCreateFacility } from '../hooks/useCreateFacility'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle, HiOutlineOfficeBuilding } from 'react-icons/hi'

import {
  Box,
  FormErrorMessage,
  FormLabel,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'

import {
  AddressSearch,
  Button,
  FormInputControl,
  FormInputSelectControl,
  PinMarker,
  StaticMapBox,
} from '@/components/ui'
import { StyledFormInputControl } from '@/components/ui'
import { CreateFacilityInput, FacilityType } from '@/graphql/generated/schemas'

import { FormInputsIProps, LatLngStateIProps } from '../types/types'
import {
  getErrorMessage,
  latRegex,
  lonRegex,
  validateLatitude,
  validateLongitude,
} from '../utils/utils'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateFacilityModal = ({ isOpen, onClose }: IProps) => {
  const {
    handleSubmit,
    errors,
    control,
    trigger,
    register,
    formState,
    setValue,
    unregister,
    reset,
    watch,
    getValues,
  } = useForm({
    mode: 'onChange',
  })
  const [latLng, setLatLng] = useState<LatLngStateIProps | null>(null)
  const [shouldShowMap, setShouldShowMap] = useState(false)
  const { isValid, isSubmitting } = formState
  const type = watch('type')?.value
  const { latitude, longitude } = watch(['latitude', 'longitude', 'address'])
  const { showSuccess, showError } = useToasts()
  const { create, facilityTypes } = useCreateFacility()

  const onSubmit: SubmitHandler<FormInputsIProps> = async (values) => {
    const input: CreateFacilityInput = {
      name: values.name,
      shortName: values.shortName.toLocaleUpperCase(),
      type: values.type?.value,
      address: values?.address ? [values.address] : null,
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),
    }
    try {
      await create(input)
      showSuccess(`Successfully created ${values.name} Facility`)
      onClose()
    } catch (e) {
      showError(getErrorMessage(e, values.name, 'creating'))
    }
  }

  useEffect(() => {
    if (latRegex.test(latitude) && lonRegex.test(longitude)) {
      setLatLng({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      })
      setShouldShowMap(true)
    }
  }, [latitude, longitude, type])

  useEffect(() => {
    if (!isOpen) {
      reset()
      setShouldShowMap(false)
      setLatLng(null)
    }
  }, [isOpen])

  useEffect(() => {
    if (type === FacilityType.Market) {
      handleMarketForm()
    } else {
      register('address', { required: true })
      register('latitude', { required: true })
      register('longitude', { required: true })
    }
    trigger(['address'])
  }, [type])

  const handleMarketForm = () => {
    reset({ ...getValues(), latitude: null, longitude: null, address: null })
    setShouldShowMap(false)
    unregister(['address', 'latitude', 'longitude'])
  }

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff' overflow='hidden'>
        <ModalCloseButton color='#000' opacity={0.4} />

        <ModalBody p='0'>
          <Box px='10' py='6'>
            <Grid gap='10px' gridTemplateColumns='30px 1fr'>
              <HiOutlineOfficeBuilding size='30px' />
              <Box>
                <Box
                  color='#353849'
                  fontSize='26px'
                  fontWeight='extrabold'
                  letterSpacing='-0.4px'
                  lineHeight='1'
                  mb='3'
                >
                  Create Facility
                </Box>
              </Box>
            </Grid>
          </Box>
          <form>
            <Box px='6'>
              <Box mb='3'>
                <FormInputControl
                  errorMessage={errors.name && errors.name.message}
                  id='name'
                  inputRef={register({
                    required: 'Facility Name is required',
                  })}
                  isInvalid={errors.name}
                  label='Facility Name'
                  placeholder='Enter the Facility name'
                />
              </Box>
              <Box mb='3'>
                <FormInputControl
                  errorMessage={errors.shortName && errors.shortName.message}
                  id='shortName'
                  inputRef={register({
                    required: 'Facility Initials are required',
                    maxLength: {
                      value: 4,
                      message: 'Must be less than 4 characters',
                    },
                  })}
                  isInvalid={errors.shortName}
                  label='Short Name'
                  placeholder='Enter the Facility initials'
                />
              </Box>
              <Box mb='3'>
                <FormInputSelectControl
                  control={control}
                  dataTestId='createFacility_facilityName'
                  defaultValue={null}
                  id='type'
                  isInvalid={errors.type}
                  label='Facility Type'
                  options={facilityTypes}
                  placeholder='Select the Facility type'
                  rules={{ required: true }}
                />
              </Box>

              <StyledFormInputControl mb='4'>
                <FormLabel>Address</FormLabel>
                <AddressSearch
                  defaultValue={null}
                  disabled={type === FacilityType.Market}
                  onSelectAddress={(address) => {
                    setValue('address', address?.value)
                    if (address) {
                      setValue('latitude', address?.latitude)
                      setValue('longitude', address?.longitude)
                      setLatLng({
                        lat: address?.latitude,
                        lng: address?.longitude,
                      })
                      setShouldShowMap(true)
                      trigger(['address', 'latitude', 'longitude'])
                    }
                  }}
                  showTooltip={false}
                />
                <FormErrorMessage>
                  {errors.address && errors.address.message}
                </FormErrorMessage>
              </StyledFormInputControl>

              <HStack align='stretch' mb='4' spacing='3'>
                <FormInputControl
                  disabled={type === FacilityType.Market}
                  errorMessage={errors.latitude && errors.latitude.message}
                  id='latitude'
                  inputRef={register({
                    validate: (v) => validateLatitude(v, type),
                  })}
                  isInvalid={Boolean(errors.latitude)}
                  label='Latitude'
                  placeholder='Latitude'
                />
                <FormInputControl
                  disabled={type === FacilityType.Market}
                  errorMessage={errors.longitude && errors.longitude.message}
                  id='longitude'
                  inputRef={register({
                    validate: (v) => validateLongitude(v, type),
                  })}
                  isInvalid={Boolean(errors.longitude)}
                  label='Longitude'
                  placeholder='Longitude'
                />
              </HStack>
            </Box>

            {shouldShowMap && (
              <StaticMapBox
                height='150px'
                latitude={latLng?.lat}
                longitude={latLng?.lng}
              >
                <PinMarker
                  Icon={HiOutlineOfficeBuilding}
                  bgGradient='linear(to-r, #1CFDE5, #07DB94)'
                  borderColor='#42CD70'
                  fadedPulseBoxShadow='rgba(43, 210, 180, 0)'
                  pulseBoxShadow='rgba(43, 210, 180, 0.4)'
                />
              </StaticMapBox>
            )}
          </form>
        </ModalBody>
        <ModalFooter py='4'>
          <Button mr='3' onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            isDisabled={!isValid}
            isLoading={isSubmitting}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Creating Facility'
            onClick={handleSubmit(onSubmit)}
          >
            Create Facility
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
