import { useState } from 'react'

import { Box } from '@chakra-ui/react'

export const ShowMore = ({
  message,
  characterLimit = 180,
  handleShowMore,
  handleShowLess,
}: {
  message: string
  characterLimit?: number
  handleShowMore?: () => void
  handleShowLess?: () => void
}) => {
  const [isExpanded, setExpanded] = useState(false)
  return message.length < characterLimit ? (
    <Box as='span'>{message}</Box>
  ) : (
    <>
      <Box as='span'>
        {isExpanded ? message : message.substring(0, characterLimit) + '...'}
      </Box>
      <button
        onClick={() => {
          isExpanded ? handleShowLess?.() : handleShowMore?.()
          setExpanded((prevExpanded) => !prevExpanded)
        }}
        style={{
          display: 'block',
          margin: '0 auto',
          color: '#3157ad',
          marginTop: 5,
          fontWeight: 500,
          fontSize: '14px',
        }}
      >
        Show {isExpanded ? 'Less' : 'More'}
      </button>
    </>
  )
}
