import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Box, Grid, GridItem } from '@chakra-ui/react'

// ui
import {
  Button,
  FormInputSelectControl,
  FormTextareaControl,
} from '@/components/ui'
import {
  IncidentDocument,
  useCreateIncidentActionMutation,
  useTechniciansQuery,
} from '@/graphql/generated/hooks'
import {
  DeviceType,
  IncidentActionType,
  IncidentStatus,
} from '@/graphql/generated/schemas'
import { BUTTON_PRESS, mixpanel } from '@/utils/analytics'
import { getTechnicianOptions } from '@/utils/forms/selectOptions'

import { MixpanelDataIProps } from '../../types/types'

export interface OrderRepairFormIProps {
  incidentId: string
  facilityId: string
  deviceType: DeviceType
  incidentStatus: IncidentStatus
  mixpanelData?: MixpanelDataIProps
  onActionComplete?: () => void
}

type FormValues = {
  technician: {
    label: string
    value: string
  }
  orderMessage: string
}

export const OrderRepairForm = ({
  incidentId,
  facilityId,
  deviceType,
  incidentStatus,
  mixpanelData,
  onActionComplete,
}: OrderRepairFormIProps) => {
  const { shouldEnableIncidentActions } = usePermissions()
  const { showSuccess, showError } = useToasts()

  const [createIncidentActionMutation] = useCreateIncidentActionMutation()

  const { data } = useTechniciansQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        facilityIdsContain: facilityId,
        deviceTypesContain: deviceType,
      },
    },
  })
  const technicianOptions = getTechnicianOptions(data)

  const onCancel = () => {
    mixpanel.track(`${BUTTON_PRESS} Order Repair - Cancel`, mixpanelData)
    onActionComplete?.()
  }

  const { handleSubmit, errors, register, formState, control } =
    useForm<FormValues>({
      mode: 'onChange',
      defaultValues: {
        technician: null,
        orderMessage: ``,
      },
    })
  const { isSubmitting, isValid } = formState

  const isAcknowledged = IncidentStatus.Active !== incidentStatus

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    try {
      await createIncidentActionMutation({
        variables: {
          input: {
            incidentId,
            type: IncidentActionType.OrderDeviceRepair,
            technicianId: values.technician.value,
            message: values.orderMessage,
          },
        },
        refetchQueries: [
          {
            query: IncidentDocument,
            variables: {
              id: incidentId,
            },
          },
        ],
      })
      showSuccess('Device repair ordered.')
      mixpanel.track(
        `${BUTTON_PRESS} Order Repair - Send Request`,
        mixpanelData
      )
      onActionComplete?.()
    } catch {
      showError()
    }
  }

  return (
    <>
      <Grid gap='18px' mb={2} mt={2} templateColumns='1fr 80px 115px'>
        <GridItem colSpan={3}>
          <FormInputSelectControl
            control={control}
            errorMessage={!!errors?.technician && 'Select a technician.'}
            id='technician'
            isInvalid={!!errors?.technician}
            label='Technician'
            options={technicianOptions}
            placeholder='Select a Technician'
            rules={{ required: true }}
          />
        </GridItem>
        <GridItem colSpan={3}>
          <FormTextareaControl
            errorMessage={errors?.orderMessage && errors?.orderMessage.message}
            id='orderMessage'
            isInvalid={!!errors?.orderMessage}
            label='Order Message'
            labelFontWeight='500'
            placeholder='Enter the order message'
            textareaRef={register({
              maxLength: {
                value: 255,
                message: 'Order message should have less than 256 characters',
              },
              required: 'Order message is required.',
            })}
          />
        </GridItem>
      </Grid>
      <Box mb={8} ml='auto' mt={5} width='fit-content'>
        <Button mr='14px' onClick={onCancel} variant='secondary'>
          Cancel
        </Button>
        <Button
          isDisabled={
            !(isAcknowledged && isValid) || !shouldEnableIncidentActions
          }
          isLoading={isSubmitting}
          loadingText='Sending Request...'
          onClick={handleSubmit(onSubmit)}
          style={{ marginLeft: 'auto' }}
        >
          Send Request
        </Button>
      </Box>
    </>
  )
}
