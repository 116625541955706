import { Circle, SkeletonCircle } from '@chakra-ui/react'

import { useGlobalUnresolvedIncidentsCountQuery } from '@/graphql/generated/hooks'

export const IncidentsCount = () => {
  const { data: totalCountData, loading: isLoading } =
    useGlobalUnresolvedIncidentsCountQuery()
  const totalCount = totalCountData?.globalUnresolvedIncidentsCount?.totalCount

  return isLoading ? (
    <SkeletonCircle paddingX='8px' size='22px' />
  ) : (
    <Circle
      bgColor='#D11D3A'
      color='#fff'
      data-testid='notificationsTab_activeIncidents_countBadge'
      fontSize='12px'
      fontWeight='bold'
      h='22px'
      paddingX='8px'
    >
      {totalCount}
    </Circle>
  )
}
