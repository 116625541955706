import { useRef } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import { Box, Center, Spinner } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'

import { Notification } from '@/graphql/appsync-notifications/types'

import { InboxListItem } from './InboxListItem'

export const InboxList = ({
  notifications,
  isLoading,
  onEndReached,
  onEndReachedThreshold = 0.95,
}: {
  notifications?: Notification[]
  isLoading: boolean
  onEndReached: () => void
  onEndReachedThreshold?: number
}) => {
  const ref = useRef()

  const debouncedOnEndReached = useDebouncedCallback(onEndReached, 500)

  const handleScroll = ({ currentTarget }) => {
    const scrollPosition = currentTarget.scrollTop + currentTarget.clientHeight
    if (scrollPosition >= currentTarget.scrollHeight * onEndReachedThreshold) {
      debouncedOnEndReached()
    }
  }

  if (isLoading) {
    return <InboxSpinner />
  }

  return (
    <Box
      data-testid='InboxList:wrapper'
      h='full'
      onScroll={handleScroll}
      overflowY='scroll'
      ref={ref}
    >
      <Box
        _after={{
          content: '""',
          pos: 'absolute',
          h: 'calc(100% - 18px)',
          w: '2px',
          top: '50%',
          left: '1px',
          transform: 'translateY(-50%)',
        }}
        pos='relative'
      >
        {notifications?.length > 0 ? (
          notifications?.map((n) => (
            <InboxListItem key={n?.notificationTimestamp} notification={n} />
          ))
        ) : (
          <Box fontWeight='semibold' py={3} textAlign='center'>
            No messages.
          </Box>
        )}
      </Box>
    </Box>
  )
}

const InboxSpinner = () => (
  <Center data-testid='InboxList:wrapper' height='100%' width='full'>
    <AnimatePresence>
      <Spinner
        animate={{ opacity: 1 }}
        as={motion.div}
        color='#298bbd'
        data-testid='InboxSpinner'
        emptyColor='gray.300'
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        size='md'
        thickness='4px'
      />
    </AnimatePresence>
  </Center>
)
