import { Guards, VoiceMessage } from '@/components/icons'

export const getGuardActionText = (shouldDispatch: boolean) => {
  const actionName = `${shouldDispatch ? 'Dispatch' : 'Radio'} Guards`
  const actionDescription = shouldDispatch
    ? 'Add a voice message to dispatch a guard'
    : 'Send a voice message to the guards'
  const actionButtonLabel = shouldDispatch ? 'Dispatch' : 'Send message'
  const actionButtonLoadingLabel = shouldDispatch
    ? 'Dispatching...'
    : 'Sending message...'
  return {
    actionName,
    actionDescription,
    actionButtonLabel,
    actionButtonLoadingLabel,
  }
}

export const getGuardActionIcon = (shouldDispatch: boolean) =>
  shouldDispatch ? Guards : VoiceMessage
