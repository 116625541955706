import { useState } from 'react'

type IndexType = number | null

interface UseTabsIProps {
  tabIndex: IndexType
  selectTab: (index: number) => void
  closeTabs: () => void
}

export const useTabs = (initialIndex: IndexType = null): UseTabsIProps => {
  const [tabIndex, setTabIndex] = useState<null | number>(initialIndex)

  const selectTab = (index: number) => {
    setTabIndex((previousIndex) => (previousIndex === index ? null : index))
  }

  const closeTabs = () => {
    setTabIndex(null)
  }

  return { tabIndex, selectTab, closeTabs }
}
