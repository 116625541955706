import { Box } from '@chakra-ui/react'

import { User } from '@/graphql/generated/schemas'

import { GuardAvatars } from './GuardAvatars'
import { GuardAvatarsSkeleton } from './GuardAvatarsSkeleton'

interface IProps {
  header: string
  subHeader?: string
  dateTime?: string
  guards?: Partial<User>[]
  isGuardsLoading?: boolean
}

export const RadioGuardHeader = ({
  header,
  subHeader,
  dateTime,
  guards,
  isGuardsLoading,
}: IProps) => {
  return (
    <>
      <Box
        color='#282424'
        fontSize='24px'
        fontWeight='bold'
        letterSpacing='-0.8px'
        lineHeight='30px'
      >
        {header}
      </Box>
      {subHeader && (
        <Box
          alignItems='center'
          color='#282424'
          display='flex'
          flexDirection='row'
          fontSize='16px'
          justifyContent='space-between'
          letterSpacing='-0.53px'
          lineHeight='20px'
          mt={2}
        >
          {subHeader}
          {isGuardsLoading ? (
            <GuardAvatarsSkeleton />
          ) : (
            <GuardAvatars guards={guards} />
          )}
        </Box>
      )}
      {dateTime && (
        <Box
          color='#a0a1a9'
          fontSize='11px'
          fontWeight='medium'
          letterSpacing='-0.33px'
        >
          {dateTime}
        </Box>
      )}
    </>
  )
}
