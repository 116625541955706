import { useState } from 'react'

import { useReportDownload } from '../hooks/useReportDownload'
import { useToasts } from '@/hooks/useToasts'
import { HiOutlineDownload } from 'react-icons/hi'

import { Button, Tooltip } from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/react'

import { DownloadReportButtonIProps } from '../types/types'
import { ReportTemplate } from './ReportTemplate'

export const DownloadReportButton = ({
  incident,
}: DownloadReportButtonIProps) => {
  const template = ReportTemplate({ incident })
  const { showError } = useToasts()
  const [loading, setLoading] = useState(false)

  const { handleReportDownload } = useReportDownload()

  const downloadReport = async () => {
    setLoading(true)

    try {
      await handleReportDownload(
        template,
        `${incident?.displayId}-${incident?.name} Report.pdf`
      )
      setLoading(false)
    } catch {
      setLoading(false)
      showError('Download Failed. Please try again later')
    }
  }

  return (
    <Tooltip
      arrow='#1b253f'
      bgColor='#1b253f'
      color='white'
      data-testid='incidentDrawer_body_downloadReportButton'
      hasArrow
      label='Download Incident Report'
      mt='5px'
      p='10px'
      shouldWrapChildren
    >
      <Button
        _active={{ bgColor: '#5f96f5', color: '#fff' }}
        _disabled={{
          opacity: 0.4,
          cursor: 'not-allowed',
          _hover: {
            bgColor: '#e3eefb',
          },
        }}
        _focus={{ outline: 0 }}
        _hover={{ bgColor: '#1b253f', color: '#5a6c9a' }}
        border='none'
        disabled={loading}
        ms='5px'
        onClick={downloadReport}
        outline='none'
        variant='secondary'
      >
        {loading ? (
          <Spinner height='16px' width='16px' />
        ) : (
          <HiOutlineDownload />
        )}
      </Button>
    </Tooltip>
  )
}
