import { useState } from 'react'

import { As, Button, Icon, Tooltip, useTab } from '@chakra-ui/react'

interface IncidentActionTabI {
  icon: As<any>
  label: string
  onClick: () => void
}

export const IncidentActionTab = ({
  icon,
  label,
  ...props
}: IncidentActionTabI) => {
  const tabProps = useTab({ ...props })
  const isSelected = !!tabProps['aria-selected']

  const [isHovering, setIsHovering] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const bgColor = isSelected ? '#5f96f5' : '#e3eefb'
  const hoverBgColor = isSelected ? '#5f96f5' : '#1b253f'
  const iconColor = isSelected ? '#ffffff' : '#3d7fce'
  const hoverIconColor = isSelected ? '#ffffff' : '#5a6c9a'

  return (
    <Tooltip
      arrow={hoverBgColor}
      bg={hoverBgColor}
      color='white'
      display={isSelected ? 'none' : 'inherit'}
      hasArrow
      isDisabled={isSelected}
      label={label}
      mt='5px'
      openDelay={250}
      p='10px'
    >
      <button
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        onMouseOut={() => setIsHovering(false)}
        onMouseOver={() => setIsHovering(true)}
      >
        <Button
          _active={{ bgColor: hoverBgColor }}
          _focus={{ bgColor: hoverBgColor }}
          _hover={{ bgColor: hoverBgColor }}
          bgColor={bgColor}
          border='none'
          isSelected={isSelected}
          ml='5px'
          {...tabProps}
        >
          <Icon
            as={icon}
            boxSize='18px'
            color={isHovering || isFocused ? hoverIconColor : iconColor}
            strokeColor={isHovering || isFocused ? hoverIconColor : iconColor}
          />
          {tabProps.children}
        </Button>
      </button>
    </Tooltip>
  )
}
