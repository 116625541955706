import { useDispatch } from 'react-redux'

import { Circle, SkeletonCircle } from '@chakra-ui/react'

import { useGetNotificationCountQuery } from '@/graphql/appsync-notifications/queries/getNotificationCount'
import { useAppSelector } from '@/redux/hooks'
import {
  selectNotificationCount,
  setNotificationCount,
} from '@/redux/notifications/notificationsSlice'

import { getNotificationCountString } from '../utils/format'

export const InboxItemsCount = () => {
  const count = useAppSelector(selectNotificationCount)
  const dispatch = useDispatch()

  const { loading: isLoading } = useGetNotificationCountQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onCompleted: (data) => {
      dispatch(
        setNotificationCount(data?.getNotificationCount?.notificationCount)
      )
    },
  })

  return isLoading ? (
    <SkeletonCircle paddingX='8px' size='22px' />
  ) : (
    <Circle
      bgColor='#D11D3A'
      color='#fff'
      data-testid='notificationsTab_inbox_countBadge'
      fontSize='12px'
      fontWeight='bold'
      h='22px'
      paddingX='8px'
    >
      {getNotificationCountString(count)}
    </Circle>
  )
}
