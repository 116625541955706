import { Box, Skeleton } from '@chakra-ui/react'

import { AudioPlayer } from '@/components/ui'
import { getRoleDisplayName } from '@/features/managed-accounts'
import { NotificationSubtitle } from '@/features/notification'
import { Notification } from '@/graphql/appsync-notifications/types'
import { useNotificationAudioMessagesQuery } from '@/graphql/generated/hooks'

export const AudioNotification = ({
  notification,
  onClick,
}: {
  notification: Notification
  onClick: () => void
}) => {
  const { data, loading: isLoading } = useNotificationAudioMessagesQuery({
    variables: {
      id: notification.audioMessageId,
    },
  })
  const url = data?.audioMessages.edges[0]?.node.audioClip.url

  const roleDisplayName = getRoleDisplayName(notification.audioMessageUserRole)
  const senderDisplayName = `${roleDisplayName} ${notification.audioMessageUserName}`

  return (
    <Box flex={1}>
      <Box cursor='pointer' onClick={onClick}>
        <Box
          data-testid='AudioNotification:header'
          fontSize='13.5px'
          fontWeight='600'
          mb={1.5}
          noOfLines={2}
        >
          {senderDisplayName} sent a Voice Message to{' '}
          <Box as='span' color='#2E6CDB'>
            {notification.incidentDisplayId} {notification.incidentName}
          </Box>
        </Box>
        <NotificationSubtitle
          facilityShortName={notification?.facilityShortName}
          fontSize='11px'
          sourceName={notification?.incidentSourceName}
          timestamp={notification?.notificationTimestamp}
        />
      </Box>
      {isLoading ? (
        <Skeleton height='34px' width='91%' />
      ) : (
        <Box mt='-14px'>{url && <AudioPlayer url={url} />}</Box>
      )}
    </Box>
  )
}
