import { useNavigation } from '@/hooks/useNavigation'

import {
  Box,
  Flex,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Square,
  VStack,
} from '@chakra-ui/react'

import { AccessControl, Camera, Floor, Incident } from '@/components/icons'
import { useFacilityFloorsQuery } from '@/graphql/generated/hooks'

interface IProps {
  facilityId: string
  isOpen: boolean
  onClose: () => void
}

const ListItemSkeleton = () => {
  return (
    <Grid
      alignItems='center'
      gap='2'
      py='2'
      templateColumns='48px 1fr'
      w='full'
    >
      <Skeleton h='48px' rounded='full' w='48px' />
      <Box>
        <Skeleton h='16px' maxW='220px' w='full' />
      </Box>
    </Grid>
  )
}

const ListItem = ({
  index,
  name,
  alertCount,
  cameraCount,
  acCount,
  onClick,
}: {
  index: number
  name: string
  alertCount: number
  cameraCount: number
  acCount: number
  onClick: () => void
}) => {
  return (
    <Grid
      alignItems='center'
      border='1px solid #D5DCE4'
      boxShadow='base'
      cursor='pointer'
      data-testid={`facilitiesPage_floorsModal_item${index}`}
      gap='4'
      onClick={onClick}
      px='4'
      py='2'
      rounded='4px'
      templateColumns='1fr auto auto auto'
      w='full'
    >
      <Box fontSize='18px' fontWeight='bold' isTruncated letterSpacing='-0.4px'>
        {name}
      </Box>
      <Box>
        {alertCount > 0 && (
          <Square
            bgColor='#D01030'
            boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
            rounded='4px'
            size='40px'
          >
            <Flex
              alignItems='center'
              flexDirection='column'
              justifyContent='center'
            >
              <Box
                color='#fff'
                fontSize='12px'
                fontWeight='medium'
                letterSpacing='-0.67px'
              >
                {alertCount}
              </Box>
              <Icon as={Incident} boxSize='18px' strokeColor='#fff' />
            </Flex>
          </Square>
        )}
      </Box>

      <Flex alignItems='center' flexDirection='column' justifyContent='center'>
        <Box
          color='#000'
          fontSize='12px'
          fontWeight='medium'
          letterSpacing='-0.67px'
        >
          {cameraCount}
        </Box>
        <Icon as={Camera} boxSize='18px' strokeColor='#000' />
      </Flex>
      <Flex alignItems='center' flexDirection='column' justifyContent='center'>
        <Box
          color='#000'
          fontSize='12px'
          fontWeight='medium'
          letterSpacing='-0.67px'
        >
          {acCount}
        </Box>
        <Icon as={AccessControl} boxSize='18px' strokeColor='#000' />
      </Flex>
    </Grid>
  )
}

export const FacilityFloorsModal = ({
  isOpen,
  onClose,
  facilityId,
}: IProps) => {
  const { goToFloorPlan } = useNavigation()
  const { data, loading: isLoading } = useFacilityFloorsQuery({
    variables: {
      id: facilityId,
    },
    fetchPolicy: 'network-only',
  })

  const openFloorPlan = (floorId: string) => {
    if (!floorId) return
    onClose()
    goToFloorPlan(floorId)
  }

  return (
    <Modal
      id='facilitiesPage_floorsModal'
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      scrollBehavior='inside'
      size='md'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader borderBottom='1px solid #D5DCE4' px='4'>
          <Box alignItems='center' d='flex'>
            <Icon as={Floor} boxSize={6} strokeColor='#000' />
            <Box ml={3}>
              <Box
                color='brand.500'
                fontSize='2xl'
                fontWeight='bold'
                lineHeight='1'
              >
                Floors
              </Box>
            </Box>
          </Box>
        </ModalHeader>
        <ModalCloseButton color='#000' mt='5px' opacity={0.4} />
        <ModalBody p='4'>
          {!data || isLoading ? (
            <>
              <ListItemSkeleton />
              <ListItemSkeleton />
              <ListItemSkeleton />
            </>
          ) : (
            <VStack spacing='2'>
              {data?.facilityFloors?.edges?.map((f, i) => (
                <ListItem
                  acCount={f?.node?.doors?.totalCount}
                  alertCount={f?.node?.incidents?.totalCount}
                  cameraCount={f?.node?.cameras?.totalCount}
                  index={i}
                  key={i}
                  name={f?.node?.name}
                  onClick={() => openFloorPlan(f?.node.id)}
                />
              ))}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
