import { useSetIsIncidentReal } from '../hooks/useSetIsIncidentReal'
import { usePermissions } from '@/hooks/usePermissions'

import { Box, Flex, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { IncidentQueryResult } from '@/graphql/generated/hooks'
import { IncidentStatus } from '@/graphql/generated/schemas'

import { ResolveToggle } from '../resolve/components/ResolveToggle'
import { SOPIncidentDrawerToggle } from '../sop-incident-drawer'
import { IncidentAction, MixpanelDataIProps } from '../types/types'
import { IncidentActionTab } from './IncidentActionTab'

interface IncidentActionsIProps {
  incidentActions: IncidentAction[]
  tabIndex: number
  onTabClick: (index: number, label: string) => void
  incident: IncidentQueryResult['data']['incident']
  mixpanelData?: MixpanelDataIProps
}

export const IncidentActions = ({
  incidentActions,
  tabIndex,
  incident,
  mixpanelData,
  onTabClick,
}: IncidentActionsIProps) => {
  const { shouldEnableSOPIncidentDrawerToggle } = usePermissions()
  const incidentId = incident?.id
  const setIsIncidentReal = useSetIsIncidentReal()

  const onToggleChange = (real: boolean) => {
    setIsIncidentReal(incidentId, real, true, mixpanelData)
  }

  return (
    <Box p='24px' pb='0'>
      <Tabs index={tabIndex} isLazy isManual variant='unstyled'>
        <Box
          alignItems='center'
          display='flex'
          justifyContent='space-between'
          mb='15px'
        >
          {shouldEnableSOPIncidentDrawerToggle ? (
            <SOPIncidentDrawerToggle showLabel={incidentActions.length < 5} />
          ) : (
            <Flex flex={1} />
          )}
          {incident?.status === IncidentStatus.Resolved && (
            <ResolveToggle
              incidentId={incident?.id}
              isReal={incident?.real}
              mixpanelData={mixpanelData}
              onChange={onToggleChange}
              style={{
                flex: 1,
                marginRight: '20px',
                justifyContent: 'flex-end',
              }}
            />
          )}
          <TabList>
            {incidentActions.map((a) => {
              if ('panel' in a) {
                return (
                  <IncidentActionTab
                    data-testid={`incidentDrawer_actionButtons_${a.id}`}
                    icon={a.icon}
                    key={a.label}
                    label={a.label}
                    onClick={() => onTabClick(a.tabIndex, a.label)}
                  />
                )
              } else {
                const CustomActionTab = a?.customActionTab
                return (
                  <CustomActionTab
                    data-testid={`incidentDrawer_actionButtons_${a.id}`}
                    key={a.label}
                  />
                )
              }
            })}
          </TabList>
        </Box>
        <TabPanels>
          {incidentActions.map((a, i) => {
            if ('panel' in a) {
              return (
                <TabPanel key={i} p={0}>
                  <motion.div
                    animate={{ opacity: 1, translateX: 0, translateY: 0 }}
                    initial={{
                      opacity: 0,
                      translateX: 0,
                      translateY: -20,
                    }}
                    transition={{ duration: 0.2, ease: 'easeInOut' }}
                  >
                    {a.panel}
                  </motion.div>
                </TabPanel>
              )
            }
          })}
        </TabPanels>
        <Box bgColor='#e2e8f0' h='1px' w='full' />
      </Tabs>
    </Box>
  )
}
