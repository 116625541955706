import { useState } from 'react'

import { useUploadAudioFile } from '../hooks/useUploadAudioFile'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'

import { Box, Icon, Stack } from '@chakra-ui/react'
import { format } from 'date-fns'

import { Button } from '@/components/ui'
import { VoiceRecorder } from '@/components/ui'
import {
  IncidentDocument,
  useCreateAudioMessageMutation,
  useCreateIncidentActionMutation,
  useFacilityGuardsQuery,
} from '@/graphql/generated/hooks'
import { IncidentActionType } from '@/graphql/generated/schemas'
import { BUTTON_PRESS, mixpanel } from '@/utils/analytics'

import { MixpanelDataIProps } from '../../types/types'
import { getGuardActionIcon, getGuardActionText } from '../utils/guardActions'
import { RadioGuardHeader } from './RadioGuardHeader'

interface IProps {
  facilityId?: string
  incidentId?: string
  mixpanelData?: MixpanelDataIProps
  shouldDispatch?: boolean
  onActionComplete?: () => void
}

export const RadioGuards = ({
  facilityId,
  incidentId,
  mixpanelData,
  onActionComplete,
  shouldDispatch = false,
}: IProps) => {
  const { shouldEnableIncidentRadioGuards } = usePermissions()
  const { showSuccess, showError } = useToasts()
  const [isLoading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const uploadAudioFile = useUploadAudioFile()

  const [createAudioMessage] = useCreateAudioMessageMutation()
  const [createIncidentAction] = useCreateIncidentActionMutation()

  const { data: guardsData, loading: isGuardsLoading } = useFacilityGuardsQuery(
    {
      variables: { facilityId },
      fetchPolicy: 'network-only',
    }
  )
  const guards = guardsData?.checkedInUsers?.edges.map((edge) => edge.node)

  const {
    actionName,
    actionDescription,
    actionButtonLabel,
    actionButtonLoadingLabel,
  } = getGuardActionText(shouldDispatch)

  const generateFile = ({ blob }: { blob: BlobPart }) => {
    const file = new File([blob], new Date().valueOf().toString(), {
      type: 'audio/wav',
    })
    setFile(file)
  }

  const incidentQuery = {
    query: IncidentDocument,
    variables: {
      id: incidentId ?? '',
    },
  }

  const onRecording = () => {
    mixpanel.track(`${BUTTON_PRESS} ${actionName} - Recording`, mixpanelData)
  }

  const onSuccess = (audioMessageId: string) => {
    showSuccess(`Audio message successfully created.`)
    setLoading(false)
    setFile(null)
    mixpanel.track(`${BUTTON_PRESS} ${actionName} - Send Message`, {
      ...mixpanelData,
      audioMessageId,
    })
    onActionComplete?.()
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      const audioClipId = (await uploadAudioFile({
        file,
        facilityId,
        incidentId,
      })) as string
      try {
        if (shouldDispatch) {
          const { data: incidentActionData } = await createIncidentAction({
            variables: {
              input: {
                incidentId,
                type: IncidentActionType.Dispatch,
                audioClipId,
              },
            },
            refetchQueries: [incidentQuery],
          })
          onSuccess(
            incidentActionData?.createIncidentAction?.incidentAction
              ?.audioMessageId
          )
        } else {
          const { data: audioMessageData } = await createAudioMessage({
            variables: {
              input: {
                facilityId,
                incidentId,
                audioClipId,
              },
            },
            refetchQueries: [incidentQuery],
          })
          onSuccess(audioMessageData?.createAudioMessage?.audioMessage?.id)
        }
      } catch (error) {
        showError()
        setLoading(false)
      }
    } catch (error) {
      showError()
      setLoading(false)
    }
  }

  const onCancel = () => {
    mixpanel.track(
      `${BUTTON_PRESS} ${actionName} - Cancel Button`,
      mixpanelData
    )
    onActionComplete?.()
  }

  return (
    <Box mb={8}>
      <RadioGuardHeader
        dateTime={format(new Date(), 'MM/dd/yyyy')}
        guards={guards}
        header={actionName}
        isGuardsLoading={isGuardsLoading}
        subHeader={actionDescription}
      />
      <VoiceRecorder
        getAudioFile={generateFile}
        hasFile={!!file}
        onRecording={onRecording}
      />
      <Stack align='center' direction='row' mt={6} spacing={3}>
        <Button
          data-testid='incidentDrawer_radioGuards_cancel'
          onClick={onCancel}
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          data-testid='incidentDrawer_radioGuards_sendVoiceMessage'
          isDisabled={!file || isLoading || !shouldEnableIncidentRadioGuards}
          isLoading={isLoading}
          leftIcon={
            <Icon as={getGuardActionIcon(shouldDispatch)} boxSize={5} />
          }
          loadingText={actionButtonLoadingLabel}
          onClick={onSubmit}
        >
          {actionButtonLabel}
        </Button>
      </Stack>
    </Box>
  )
}
