/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAvailableIncidentActions } from '../../hooks/useAvailableIncidentActions'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'

import { Box, Icon, useDisclosure } from '@chakra-ui/react'

import { Share } from '@/components/icons'
import { Button } from '@/components/ui'
import {
  GlobalIncidentsDocument,
  GlobalUnresolvedCameraIncidentsCountsDocument,
  IncidentDocument,
  useCreateIncidentActionMutation,
} from '@/graphql/generated/hooks'
import { IncidentQuery } from '@/graphql/generated/operations'
import { IncidentStatus } from '@/graphql/generated/schemas'
import { IncidentActionType } from '@/graphql/generated/schemas'
import { BUTTON_PRESS, mixpanel } from '@/utils/analytics'

import { ReleaseIncidentModal, TakeOverIncidentModal } from '../..'
import { MixpanelDataIProps } from '../../types/types'

interface FeedButtonsIProps {
  incident: IncidentQuery['incident']
  mixpanelData: MixpanelDataIProps
}

export const FeedButtons = ({ incident, mixpanelData }: FeedButtonsIProps) => {
  const { shouldEnableIncidentActions } = usePermissions()
  const { showSuccess, showError } = useToasts({ preventDuplicate: false })
  const [createIncidentAction, { loading: isLoadingCreateIncidentAction }] =
    useCreateIncidentActionMutation()

  const { isAcknowledgeEnabled, isCanReleaseEnabled, isTakeOverEnabled } =
    useAvailableIncidentActions({
      incidentStatus: incident?.status,
      ownerId: incident?.owner?.id,
      incidentCategory: incident?.manualIncidentCategory,
    })

  const incidentId = incident?.id

  const {
    isOpen: isTakeOverOpen,
    onOpen: onTakeOverOpen,
    onClose: onTakeOverClose,
  } = useDisclosure()
  const {
    isOpen: isReleaseOpen,
    onOpen: onReleaseOpen,
    onClose: onReleaseClose,
  } = useDisclosure()

  const onAcknowledgementClick = async () => {
    mixpanel.track(`${BUTTON_PRESS} Incident Acknowledge`, mixpanelData)
    try {
      await createIncidentAction({
        variables: {
          input: {
            incidentId,
            type: IncidentActionType.AcknowledgementByOperator,
          },
        },
        refetchQueries: [
          { query: IncidentDocument, variables: { id: incidentId } },
        ],
        awaitRefetchQueries: true,
      })
      showSuccess('Incident has been acknowledged.')
    } catch (e) {
      showError(e)
    }
  }

  const onReleaseClick = async () => {
    mixpanel.track(`${BUTTON_PRESS} Incident Release`, mixpanelData)
    try {
      await createIncidentAction({
        variables: {
          input: {
            incidentId,
            type: IncidentActionType.ReleaseByOperator,
          },
        },
        refetchQueries: [
          { query: IncidentDocument, variables: { id: incidentId } },
        ],
        awaitRefetchQueries: true,
      })
      onReleaseClose()
      showSuccess('Incident has been released.')
    } catch (e) {
      showError(e)
    }
  }

  const onTakeOverClick = async () => {
    mixpanel.track(
      `${BUTTON_PRESS} Incident Take Over & Acknowledge`,
      mixpanelData
    )
    try {
      await createIncidentAction({
        variables: {
          input: {
            incidentId,
            type: IncidentActionType.AcknowledgementByOperator,
          },
        },
        refetchQueries: [
          { query: IncidentDocument, variables: { id: incidentId } },
          {
            query: GlobalIncidentsDocument,
            variables: {
              filter: {
                status: IncidentStatus.Active,
              },
            },
          },
          { query: GlobalUnresolvedCameraIncidentsCountsDocument },
        ],
        awaitRefetchQueries: true,
      })
      onTakeOverClose()
      showSuccess('Incident has been taken over & acknowledged.')
    } catch (e) {
      showError(e)
    }
  }
  return (
    <>
      {isAcknowledgeEnabled && (
        <Box bg='#fff' mt='4' pos='relative'>
          <Button
            data-testid='incidentDrawer_feed_acknowledge'
            isDisabled={!shouldEnableIncidentActions}
            isLoading={isLoadingCreateIncidentAction}
            loadingText='Acknowledging...'
            onClick={onAcknowledgementClick}
          >
            Acknowledge
          </Button>
        </Box>
      )}
      {isTakeOverEnabled && (
        <Box bg='#fff' mt='4' pos='relative'>
          <Button
            data-testid='incidentDrawer_feed_takeOverAndAcknowledge'
            isLoading={isLoadingCreateIncidentAction}
            loadingText='Taking over...'
            onClick={onTakeOverOpen}
          >
            Take Over & Acknowledge
          </Button>
        </Box>
      )}
      {isCanReleaseEnabled && (
        <Box bg='#fff' mt='4' pos='relative'>
          <Button
            data-testid='incidentDrawer_feed_release'
            leftIcon={<Icon as={Share} boxSize={6} strokeColor='#fff' />}
            loadingText='Releasing...'
            onClick={onReleaseOpen}
            variant='danger'
          >
            Release
          </Button>
        </Box>
      )}
      {isReleaseOpen && isCanReleaseEnabled && (
        <ReleaseIncidentModal
          displayId={incident?.displayId}
          incidentName={incident?.name}
          isLoading={isLoadingCreateIncidentAction}
          isOpen={isReleaseOpen}
          onClose={onReleaseClose}
          onSubmit={onReleaseClick}
        />
      )}
      {isTakeOverOpen && isTakeOverEnabled && (
        <TakeOverIncidentModal
          currentOwner={`${incident?.owner.firstName} ${incident?.owner.lastName}`}
          displayId={incident?.displayId}
          incidentName={incident?.name}
          isLoading={isLoadingCreateIncidentAction}
          isOpen={isTakeOverOpen}
          nextAction={IncidentActionType.AcknowledgementByOperator}
          onClose={onTakeOverClose}
          onSubmit={onTakeOverClick}
        />
      )}
    </>
  )
}
