import { JSXElementConstructor, ReactElement } from 'react'

import { DocumentProps, pdf } from '@react-pdf/renderer'

import fontkit from '@pdf-lib/fontkit'
import downloadjs from 'downloadjs'
import { PDFDocument } from 'pdf-lib'

import { createPDFTextField, getFieldAppearanceOptions } from '../utils/utils'

export const useReportDownload = () => {
  const handleReportDownload = async (
    template: ReactElement<DocumentProps, string | JSXElementConstructor<any>>,
    reportName: string
  ) => {
    const blob = await pdf(template).toBlob()

    const formPdfBytes = await blob.arrayBuffer()
    const pdfDoc = await PDFDocument.load(formPdfBytes)

    pdfDoc.registerFontkit(fontkit)

    const form = pdfDoc.getForm()

    const pages = pdfDoc.getPages()

    const summaryField = createPDFTextField(form, 'summary', '[sumary here]')
    const actionsField = createPDFTextField(form, 'actions', '[Actions here]')

    const lastPage = pages[pages.length - 1]

    summaryField.addToPage(
      lastPage,
      getFieldAppearanceOptions(lastPage, 40, 630)
    )

    actionsField.addToPage(
      lastPage,
      getFieldAppearanceOptions(lastPage, 40, 425)
    )

    const montserrat = await pdfDoc.embedFont(
      await fetch('/fonts/Montserrat-Regular.ttf').then((res) =>
        res.arrayBuffer()
      )
    )

    actionsField.updateAppearances(montserrat)
    summaryField.updateAppearances(montserrat)
    summaryField.setFontSize(16)
    actionsField.setFontSize(16)

    const pdfBytes = await pdfDoc.save()

    downloadjs(pdfBytes, reportName, 'application/pdf')
  }

  return { handleReportDownload }
}
